import React from 'react';

const IconLoader = () => (
  <svg id="logo" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Loader Logo</title>
    <g>
      <g
        id="B"
        transform="translate(34, 33)"
        fill="currentColor"
        fontFamily="Calibre-Medium, Calibre"
        fontSize="50"
        fontWeight="500"
        letterSpacing="4.16666603">
        <text>
          <tspan x="0.141666985" y="33">
            N
          </tspan>
        </text>
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5 L 11, 27 L 11, 72 L 50, 95 L 89, 73 L 89, 28 z"></path>
    </g>
  </svg>
);

export default IconLoader;
